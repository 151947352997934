import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import * as styles from '../../styles/flex/other/roadMap.module.css';

function RoadMap() {
  return (
    <Layout>
      <Seo title="Road Map" description="A high level overview and timeline of the updates made to this website." />
        <div className={styles.Container}>
          <h1>DEV ROAD MAP</h1>
          <div className={styles.Timeline}>
            <ul>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>FUTURE UPDATE</h2>
                  <h1>Premium Tier</h1>
                  <p>A premium tier of access is now availible to users with new perks and features.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>FUTURE UPDATE</h2>
                  <h1>Sweepstakes</h1>
                  <p>Monthly shark card sweepstakes will be conducted, free to all users with an account.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>FUTURE UPDATE</h2>
                  <h1>Business Inventory Alerts</h1>
                  <p>Users can now choose a business for the website to alert you at low inventory levels.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>FUTURE UPDATE</h2>
                  <h1>Account Creation</h1>
                  <p>Account creation has been added to the website for future features.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>FUTURE UPDATE</h2>
                  <h1>Full Re-Opening</h1>
                  <p>All previous capabilites of the website have been reinstated.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>COMPLETED | 16th September, 2021</h2>
                  <h1>Partial Re-Opening</h1>
                  <p>Added minimum essential pages and functionality to the website for re-opening.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>COMPLETED 0.6.0 | 31th August, 2021</h2>
                  <h1>New User Interface</h1>
                  <p>Complete redesign of all UI with an emphasis on mobile compatablity.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>COMPLETED 0.5.0 | 20th August, 2021</h2>
                  <h1>New Frameworks</h1>
                  <p>New frameworks for rebuilding the website were indentified and implemented.</p>
                </div>
              </li>
              <li>
                <div className={styles.TimelineContent}>
                  <h2 className={styles.Date}>COMPLETED 0.0.0 | 14th July, 2021</h2>
                  <h1>GTA Max Profit 2.0</h1>
                  <p>Decision was made to completely rebuild the website in order to keep it alive.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </Layout>
  )
}

export default RoadMap;
